import type { EnvConfig } from 'config';

const mapTilerKey = 'AWdwbgaYa4nBnCEwJXQJ';

const development: EnvConfig = {
  appHost: 'http://dev.surfline.com:8080',
  appKeys: {
    segment: 'VQDMbHw65jkXg4go8KmBnDiXzeAz7GiO',
    fbAppId: process.env.FB_APP_ID || '564041023804928',
    logrocket: 'h57nhx/sl_sandbox',
    appsFlyer: 'd81e6dc0-c4cf-4c84-8d7c-351bf6cf2f03',
  },
  camerasServiceURL: 'http://cameras-service.sandbox.surfline.com',
  cdnUrl: 'https://product-cdn.sandbox.surfline.com',
  clientId: process.env.WEB_CLIENT_ID || '5bff1583ffda1e1eb8d35c4b',
  clientSecret: process.env.WEB_CLIENT_SECRET || '',
  defaultCountryCode: 'US',
  defaultImageUrl: 'https://spot-thumbnails.sandbox.surfline.com/spots/default/default_1500.jpg',
  geoTargetServiceUrl: 'http://geo-target-service.sandbox.surfline.com',
  kbygServiceURL: 'http://kbyg-api.sandbox.surfline.com',
  legacyApi: 'https://api.surfline.com/v1',
  mapTiler: {
    style: `https://api.maptiler.com/maps/345b8a3e-73ca-475f-b7e6-208e3bb15e62/style.json?key=${mapTilerKey}`,
    key: mapTilerKey,
    publicKey: 'igHgJOU9X0VrMtGuzlM6',
  },
  oneTrustDomainScript: '89f3dcce-1ffc-42b2-9d52-91fd3af3d675-test',
  react: {
    integrity:
      'sha512-Vf2xGDzpqUOEIKO+X2rgTLWPY+65++WPwCHkX2nFMu9IcstumPsf/uKKRd5prX3wOu8Q0GBylRpsDB26R6ExOg==',
    src: 'https://unpkg.com/react@17.0.2/umd/react.development.js',
  },
  reactDOM: {
    integrity:
      'sha512-Wr9OKCTtq1anK0hq5bY3X/AvDI5EflDSAh0mE9gma+4hl+kXdTJPKZ3TwLMBcrgUeoY0s3dq9JjhCQc7vddtFg==',
    src: 'https://unpkg.com/react-dom@17.0.2/umd/react-dom.development.js',
  },
  robots: 'noindex,nofollow',
  servicesURL: 'https://services.sandbox.surfline.com',
  she: {
    headerScriptUrl: 'https://ads.blogherads.com/sk/12/123/1236760/28994/header.js',
    staticScriptUrl: 'https://ads.blogherads.com/static/blogherads.js',
  },
  signInUrl: 'https://sandbox.surfline.com/sign-in',
  skipTrackingScripts: false,
  splitio: process.env.SPLITIO_AUTHORIZATION_KEY || 'cctgg8rco0ucv5ctphapda18sq0nd77hq37e',
  subscriptionServiceURL: 'http://subscription-service.sandbox.surfline.com',
  surflineEmbedURL: 'https://embed.sandbox.surfline.com',
  surflineHost: 'https://sandbox.surfline.com',
  userServiceURL: 'http://user-service.sandbox.surfline.com',
  wpAPI: 'https://sandbox.surfline.com',
};

export default development;
